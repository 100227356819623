<template>
  <div class="leftColumn">
    <graphTitle title="绑定车牌用户占比">
        <el-tooltip placement="top" content="展示统计时间段内注册的会员当中，已绑定车辆的会员数与未绑定车辆的会员数的占比情况">
                    <i class="el-icon-question my-icon" />
                  </el-tooltip>
    </graphTitle>
    <div id='piec'
         :style="{height: '260px'}"></div>
    <p style="text-align: center;color:rgba(0,0,0,0.65);font-size: 17px;">注册用户总数：{{rightData.allUsers || 0}}个</p>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    rightData: {
      // type: Array,
      default: function () {
        return {}
      }
    }
  },
  name: 'BerthchargeNumber',
  components: {
    graphTitle
  },
  data () {
    return {
      calculate: '0%',
      pie: [
        { value: 0, name: '绑定车牌会员数' },
        { value: 0, name: '非绑定车牌会员数' }
      ]
    }
  },
  watch: {
    rightData: {
      handler (value) {
        // console.log(value)
        this.pie = [];
        // // 金额
        let valueDiff = value.allUsers - value.bindUsers
        this.pie.push({ value: value.bindUsers, name: '绑定车牌会员数' }, { value: valueDiff > 0 ? valueDiff : 0, name: '非绑定车牌会员数' })
        // this.calculate = parseInt(this.pie[1].value / (this.pie[0].value + this.pie[1].value) * 100) + '%';
        if (value.allUsers) {
           this.calculate = (this.pie[0].value / (value.allUsers) * 100).toFixed(2) + '%';
        } else {
           this.calculate = '0.00%';
        }
        this.setPieC();
      },
      deep: true
    }
  },
  methods: {
    setPieC () {
      // let data = [
      //   {
      //     value: parseInt(100 * Math.random()),
      //     name: '绑定车牌会员数'
      //   }, {
      //     value: parseInt(100 * Math.random()),
      //     name: '会员数'
      //   }
      // ]
      let option = {
        color: ['#FFA924', '#DDECFD'],
        // title: {
        //   text: '绑定车牌用户占比',
        //   left: 'left',
        //   top: '0',
        //   padding: [0, 25, 0, 0],
        //   textStyle: {
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          trigger: 'item',
          padding: [15, 15, 15, 30],
          // formatter: "{a}: {c} <br/>占比: {d}%"
          formatter: (params) => {
            let tipText =
              `
								<p>
									<span style="position: absolute;left: 10px;">${params.marker}</span>${params.name}：${params.value}个
								</p>
								<p>
									<span style="position: absolute;left: 10px;">${params.marker}</span>占比：${params.percent}%
								</p>
							`
            return tipText;
          }
        },
        legend: {
          show: true,
          type: 'scroll',
          orient: 'vertical',
          left: 'right',
          top: 'center',
          data: [{
            name: '绑定车牌会员数',
            icon: 'circle'
          }, {
            name: '非绑定车牌会员数',
            icon: 'circle'
          }]
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '105px',
          z: 2,
          zlevel: 100,
          style: {
            text: this.calculate,
            fill: '#000',
            width: 100,
            height: 30,
            font: 'bolder 25px Microsoft YaHei'
          }
        },
        series: [
          {
            center: ['50%', '45%'],
            name: '绑定用户',
            type: 'pie',
            radius: ['65%', '80%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: false,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                },
                formatter: '{d}%'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: this.pie
          }
        ]
      };
      let myChart = this.$echarts.init(document.getElementById('piec'));
      myChart.setOption(option);
    }
  },
  created () {
  },
  mounted () {
    this.setPieC();
  }
}
</script>
<style scoped>
</style>
